<template>
  <section id="cards-home" class="mb-5">
    <b-row>
      <b-col cols="12" md="4" class="mb-4">
        <card-gestao-de-plano-municipal class="mb-3" />
      </b-col>
      <b-col cols="12" md="4" class="mb-4">
        <ges-conselho-card class="mb-3" />
      </b-col>
      <b-col cols="12" md="4" class="mb-4">
        <card-diagnostico-municipal class="mb-4" />

      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6" class="mb-4">
        <card-chart-gestao class="mb-3" />

      </b-col>
      <b-col cols="12" md="6" class="mb-4">
        <card-action-plans class="mb-4"/>
        <card-more-data />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import CardGestaoDePlanoMunicipal from "./GestaoDePlanoMunicipalCard.vue";
import GesConselhoCard from "./GesConselhoCard.vue";
import CardDiagnosticoMunicipal from "./DiagnosticoMunicipalCard.vue";
import CardChartGestao from "./Card/CardChartGestao.vue";
import CardMoreData from "./Card/CardMoreData.vue";

import CardActionPlans from "./Card/CardActionPlans.vue";

export default {
  components: {
    CardGestaoDePlanoMunicipal,
    CardDiagnosticoMunicipal,
    GesConselhoCard,
    CardChartGestao,
    CardMoreData,
    CardActionPlans,
  },
};
</script>
