<template>
  <b-card body-class="pt-2" header-class="pb-0 pt-4" class="card-sistemas">
    <template #header>
        <img src="@/assets/img/gesplanos.png" class="logo-card-home" />
    </template>
    <b-card-text class="text-muted text-body1">
      A ferramenta de gestão de Planos Municipais, apresenta uma visão
      estratégica da situação das Políticas Públicas.
    </b-card-text>
    <template #footer>
      <div class="div-link">
        <link-button-base
          label="Acessar ferramenta"
          :to="{ name: 'planos_estrategicos_list' }"
        />
      </div>
    </template>
  </b-card>
</template>

<script>
import { mapState } from "vuex";

import LinkButtonBase from "@/components/LinkButton/LinkButtonBase.vue";

export default {
  components: {
    LinkButtonBase,

  },
  data: () => ({}),
  computed: {
    ...mapState("clients", ["homeData"]),
    legend_planos() {
      if (this.num_planos == 0) return "Não há planos cadastrados";

      const buildPosfix = this.num_planos == 1 ? "" : "s";
      return `• ${this.num_planos} plano${buildPosfix} cadastrado${buildPosfix}`;
    },
    num_planos() {
      return this.homeData?.strategic_plans || 0;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .pie-chart {
  .echarts {
    height: 150px !important;
    width: 150px !important;
  }
}

::v-deep .card-body {
  flex-grow: 0;
}
</style>
