<template>
  <b-card body-class="pt-2" header-class="pb-0 pt-4" class="card-sistemas">
    <template #header>
      <img src="@/assets/img/infopolis.png" class="logo-card-home" />
    </template>
    <b-card-text class="text-muted text-body1">
      Um retrato municipal a partir de uma série de indicadores que refletem
      dados atuais e séries históricas capaz de compreender as principais
      políticas públicas locais.
    </b-card-text>
    <template #footer>
      <div class="div-link">
        <link-button-base
          label="Acessar ferramenta"
          :href="url"
        />
      </div>
    </template>
  </b-card>
</template>

<script>
import LinkButtonBase from "../LinkButton/LinkButtonBase.vue";
import { mapActions } from "vuex";

export default {
  components: {
    LinkButtonBase,
  },
  data: () => ({
    url: '#'
  }),
  methods: {
    ...mapActions("auth", ["ActionGenerateJwt"]),
    async urlGesConselho() {
      const token = await this.ActionGenerateJwt("infopolis");
      this.url = "http://infopolis.com.br/login/l?token=" + token;
    },
  },
  created() {
    this.urlGesConselho();
  }
};
</script>

<style lang="scss" scoped>
::v-deep .card-body {
  flex-grow: 0;
}
</style>
