<template>
  <div>
    <Echarts :options="options" ref="pie" theme="ovilia-green" autoresize />
    <pre class="d-none">{{ options }}</pre>
  </div>
</template>

<script>
import Echarts from "./Echarts";
import "echarts/lib/chart/pie";
//import "echarts/lib/component/color";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";

export default {
  components: { Echarts },
  props: {
    color: {
      type: Array,
      default: () => null,
    },
    legend: {
      type: Object,
      default: () => ({}),
    },
    tooltip: {
      type: Object,
      default: () => ({}),
    },
    xAxis: {
      type: Object,
      default: () => ({ type: "category" }),
    },
    yAxis: {
      type: Object,
      default: () => ({ type: "value" }),
    },

    series: {
      type: Array,
      required: true,
    },
  },
  data: () => ({}),
  computed: {
    options() {
      let options = {
        legend: this.legend,
        tooltip: this.tooltip,
        series: this.series,
      };

      if (this.color) {
        options.color = this.color;
      }

      return options;
    },
  },
};
</script>

<style>
</style>

