<template>
  <b-card no-body class="overflow-hidden pl-2 pr-3" :class="darkClassBody">
    <b-row align-v="center" no-gutters>
      <b-col col>
        <b-card-body :title="label">
          <b-card-text
            :class="darkClassDescription"
            class="text-description"
            v-show="description"
          >
            {{ description }}
          </b-card-text>
        </b-card-body>
      </b-col>
      <b-col sm="auto">
        <a
          v-if="isExternalLink"
          class="icon-button bg-white text-center"
          :href="href"
          target="_blank"
        >
          <b-icon
            icon="chevron-right"
            aria-hidden="true"
            class="pam-primary-dark-text"
          />
        </a>
        <router-link custom v-slot="{ navigate }" :to="to" v-else>
          <div class="icon-button bg-white text-center" @click="navigate">
            <b-icon
              icon="chevron-right"
              aria-hidden="true"
              class="pam-primary-dark-text"
            />
          </div>
        </router-link>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
export default {
  name: "LinkButtonBase",
  props: {
    to: {
      type: [Object, String],
      default: ""
    },
    href: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    dark: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    darkClassBody() {
      return this.dark ? "pam-primary-dark text-white" : "";
    },
    darkClassDescription() {
      return this.dark ? "text-gray-400" : "";
    },
    isExternalLink() {
      return !!this.href;
    }
  }
};
</script>
<style scoped lang="scss">
::v-deep .card {
  height: auto !important;
}
::v-deep .card-title {
  font-size: 1em;
}
.icon-button {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6em;
  cursor: pointer;
}

.text-description {
  font-size: 0.85em;
}
</style>
