<template>
  <b-card body-class="pt-0 pb-4">
    <template #header>
      <card-header
        :show-status="false"
        label="Dados atualizados"
        caption-top="Situação dos planos municipais"
      />
    </template>
    <b-row align-v="center">
      <b-col>
        <pie-chart
          :tooltip="tooltip"
          :series="pie_serie"
          :legend="pie_legend"
          :color="colors"
        />
      </b-col>
      <b-col sm="auto">
        <b-list-group class="text-uppercase">
          <b-list-group-item v-for="(item, key) in series_data" :key="key">
            <div
              v-b-tooltip.hover.righttop="{ variant: item.variantColor }"
              :title="item.description"
              :class="item.variantColor"
              class="indicator text-caption pl-3"
            >
              {{ item.value }}
              <span class="text-gray-700"> · {{ item.name }} </span>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import PieChart from "@/components/Echarts/Pie.vue";

import CardHeader from "./CardHeader.vue";

export default {
  name: "CardChartGestao",
  components: {
    CardHeader,
    PieChart
  },
  data() {
    return {
      labels: {
        em_alerta: {
          label: "Sob atenção",
          showOnHover:
            "Plano municipal em andamento, porém com pontos que necessitam intervenção",
          variantColor: "warning"
        },
        negativo: {
          label: "Em risco",
          showOnHover:
            "Plano municipal com pontos críticos, que necessitam intervenções urgentes",
          variantColor: "danger"
        },
        positivo: {
          label: "Bom andamento",
          showOnHover: `Plano municipal apresenta andamento normal, sem pontos críticos`,
          variantColor: "success"
        }
      }
    };
  },
  computed: {
    ...mapGetters("clients", ["getStrategicPlans", "getSeriesData"]),
    tooltip: () => ({
      trigger: "item",
      position: param => [param[0] + 10, param[0] - 10]
    }),
    series_data() {
      return this.getSeriesData(this.labels || {});
    },
    pie_serie() {
      return [
        {
          name: "Número de Planos",
          type: "pie",
          radius: ["80%", "95%"],
          data: this.series_data,
          emphasis: {
            label: {
              show: true,
              fontSize: 20,
              fontWeight: 500
            }
          },
          label: {
            show: false,
            position: "center",
            backgroundColor: "#fff"
          },
          labelLine: {
            show: false
          }
        }
      ];
    },
    pie_legend: () => ({
      show: false
    }),
    colors: () => ["#e80000", "#ffd10f", "#00a332"]
  },

};
</script>
<style scoped lang="scss">
::v-deep .echarts {
  max-width: 280px !important;
  max-height: 280px !important;
}

::v-deep .list-group-item {
  border: none;
  padding-bottom: 0;
}

.indicator {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    height: 100%;
    width: 8px;
    border-radius: 4px;
  }
  &.success::before {
    background-color: #00a332;
  }
  &.warning::before {
    background-color: #ffd10f;
  }
  &.danger::before {
    background-color: #e80000;
  }
}
</style>
