<template>
  <div id="home">
    <div class="user-info mb-5">
      <h4 class="text-white font-weight-medium">BEM VINDO AO {{ name_app }}</h4>
      <p class="text-gray-400 font-weight-normal">
        Acesse as ferramentas disponíveis para o seu município
      </p>
    </div>
    <div class="spinner-border" v-if="loading">
      <span class="sr-only">Loading...</span>
    </div>
    <Cards v-else/>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Cards from "@/components/Home/CardsContainer.vue";

export default {
  name: "Home",
  components: { Cards },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapState("auth", ["user"]),
    name_app() {
      return process.env.VUE_APP_TITLE;
    },

    user() {
      return this.$config;
    },
  },
  methods: {
    ...mapActions("clients", ["ActionLoadDataHome"]),
  },
  async created() {
    this.loading = true;
    await this.ActionLoadDataHome();
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.font-weight-medium {
  font-weight: 500;
}
</style>
