<template>
  <b-card body-class="pt-0 px-0 pb-0">
    <template #header>
      <card-header
        :show-status="false"
        label="Informações complementares"
        caption-top="Situação dos planos municipais"
      />
    </template>
    <b-list-group>
      <b-list-group-item
        v-for="(value, label) in labels"
        :key="label"
        v-once
        class="d-flex justify-content-between align-items-center text-gray-800"
      >
        {{ label }}
        <span>
          {{ value }}
        </span>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>
<script>
import CardHeader from "./CardHeader.vue";
import { mapState } from "vuex";

export default {
  name: "CardChartGestao",
  components: {
    CardHeader,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("clients", ["homeData"]),
    labels() {
      let labels = {};
      if (this.homeData && this.homeData.info_data) {
        labels = {
          Objetivos: this.homeData.info_data.objectives,
          Metas: this.homeData.info_data.measures,
          Indicadores: this.homeData.info_data.indicators,
          "Planos de Ação": this.homeData.info_data.action_plans,
        };
      }
      return labels;
    },
  },
  methods: {},
};
</script>
<style scoped lang="scss">
::v-deep .list-group-item {
  border-left: none;
  border-right: none;
  &:last-child {
    border-bottom: 0;
  }
  &:first-child {
    border-top: 0;
  }
}
</style>
