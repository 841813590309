<template>
  <b-card header-class="pb-1" body-class="pt-0">
    <template #header>
      <card-header label="Planos de Ações 5w2h" label-class="h6" :active="true" />
    </template>
    <b-card-text class="text-muted text-body1">
      Who? What? Where? When? Why? Um checklist das atividades preventivas e
      corretivas que precisam ser desenvolvidas.
    </b-card-text>
  </b-card>
</template>
<script>
export default {
  name: "CardIndicators",
}
</script>
<script setup>
import CardHeader from "./CardHeader.vue";
</script>
<style scoped lang="scss"></style>
